<template>
  
  <div>
    <v-btn @click="customExport(item)">Heyy</v-btn>
    <v-dialog  v-model="showExportDialog" persistent max-width="600px">
      <v-card v-if="minDate !== null && maxDate !== null && dateRangeSlider.length === 2">
        <v-card-title>
          <span class="headline">Paramètres d'exportation personnalisée</span>
        </v-card-title>
        <vue-slider
          v-model="dateRangeSlider"
          :min="minDate"
          :max="maxDate"
          :interval="1000" 
          :tooltip="'always'"
          :formatter="formatDate"
          :dot-options="{ tooltipFormatter: formatDate }"
          :process-formatter="formatDate"
          range
        />
        <div>
          <span>Date de début : {{ formatDate(dateRangeSlider[0]) }}</span>
          <span>Date de fin : {{ formatDate(dateRangeSlider[1]) }}</span>
        </div>
      </v-card>
    </v-dialog>
    <cube3D />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import cube3D from '../components/cube3D.vue';

export default {
  components: {
    VueSlider,
    cube3D,
  },
  data() {
    return {
      minDate : null,
      maxDate : null,
      dateRangeSlider: [
        0,
        0,
      ],
      item: {
        start_date : '2024-01-19T15:57:31.896Z',
        end_date: '2024-01-19T15:57:59.159Z'
      },
      showExportDialog: false,
    };
  },
  methods: {
    formatDate(timestamp) {
      return new Date(timestamp).toISOString().replace('T', ' ').replace('Z', '');
    },
    customExport(item){
        console.log(item.start_date)
        console.log(item.end_date)
        const min = new Date(item.start_date);
        const max = new Date(item.end_date);

        // Arrondir les dates à la seconde près (mettre les millisecondes à 0)
        min.setMilliseconds(0);
        max.setMilliseconds(0);

        // Mettre à jour minDate et maxDate avec les timestamps arrondis
        this.minDate = min.getTime();
        this.maxDate = max.getTime();

        // Calculer la différence entre les dates
        const difference = this.maxDate - this.minDate;

        // Afficher les valeurs pour le débogage
        console.log('Date de début (arrondie) :', min);
        console.log('Date de fin (arrondie) :', max);
        console.log('Différence :', difference);

        // Mettre à jour le slider de plage de dates
        this.dateRangeSlider = [
          this.minDate,
          this.maxDate,
        ];

        // Attendre que Vue ait mis à jour le DOM avant d'afficher le dialogue d'exportation
        this.$nextTick(() => {
          this.showExportDialog = true;
        });
      }
    },
  }

</script>
