<template>
    <div>  
        <v-btn @click="updateOrientation()">Rotate</v-btn> 
        <div class="cube-container">
            <div class="cube" :style="cubeStyle">
              <div class="face front"></div>
              <div class="face back"></div>
              <div class="face left"></div>
              <div class="face right"></div>
              <div class="face top"></div>
              <div class="face bottom">
            </div>
        </div>
  </div>
    </div>
  
</template>

<script>
export default {
  data() {
    return {
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
    };
  },
  computed: {
    cubeStyle() {
      return {
        transform: `rotateX(${this.rotationX}rad) rotateY(${this.rotationY}rad) rotateZ(${this.rotationZ}rad)`,
      };
    },
  },
  mounted() {
    this.updateOrientation();
    // setInterval(this.updateOrientation, 1000);
  },
  methods: {
    updateOrientation() {
      // Simulate fetching data from an API by generating random values between -2 and 2
      this.rotationX = this.getRandomValue(-2, 2);
      this.rotationY = this.getRandomValue(-2, 2);
      this.rotationZ = this.getRandomValue(-2, 2);
    },
    getRandomValue(min, max) {
      return Math.random() * (max - min) + min;
    },
  },
};
</script>

<style scoped>
.cube-container {
  width: 200px;
  height: 200px;
  perspective: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cube {
  width: 100px;
  height: 100px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s ease;
}

.face {
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(0, 128, 255, 0.7);
  border: 2px solid #005a9c;
}

.front  { transform: translateZ(50px); }
.back   { transform: rotateY(180deg) translateZ(50px); }
.left   { transform: rotateY(-90deg) translateZ(50px); }
.right  { transform: rotateY(90deg) translateZ(50px); }
.top    { transform: rotateX(90deg) translateZ(50px); }
.bottom { transform: rotateX(-90deg) translateZ(50px); }
</style>
